@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
*{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
body{
  padding-top: 60px;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
  -ms-overflow-style: none;
}
*{
  -ms-overflow-style: none;
  }
h2{
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}
.css-1ctgm5u-MuiCardContent-root.MuiCardContent-root img{
  object-fit: cover;
  object-position: 50% 0%;
  width: 100%;
  height: 85%;
}
.css-1pqm26d-MuiAvatar-img{
  object-position: 50% 0%;
}
.hiddenArrow{
  opacity: 0;
}


.css-1rwjz6-MuiCardActions-root{
  display: flex;
  margin: 0 auto;
  width: 90%;
  justify-content: space-between;
}


.css-1rwjz6-MuiCardActions-root button{
  cursor: pointer;
    box-shadow: 2px 2px 2px black;
    border: none;
    height: 40px;
}
.css-1rwjz6-MuiCardActions-root button:nth-of-type(2){
  margin-left: 10px;
}
.css-qagzk5-MuiPaper-root-MuiCard-root.MuiPaper-root svg{
  cursor: pointer;
}
.css-qagzk5-MuiPaper-root-MuiCard-root.MuiPaper-root img{
  cursor: help;
}
.css-1e4qex4-MuiTypography-root.MuiTypography-root i{
  cursor: pointer;
}
.css-1e4qex4-MuiTypography-root.MuiTypography-root p{
  cursor:default;
}
:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #e9f092;
}


.showQuoteAction{
  display: flex;
  align-items: center;
  text-align: center;
  animation: showQuoteAction 1s infinite;
  animation-delay: calc(.1s * var(--i));
  color: #018CF1;
 
  
}
.hideQuoteAction{
  display: none;
}
@keyframes showQuoteAction {
  0%,40%,100% {
    transform: translateY(0)
  }
  20% {
    transform: translateY(-20px)
  }
}

.css-1jze2pv-MuiPaper-root-MuiCard-root h3{
  cursor:pointer;
  margin: 5px auto;
}


.slick-slider .slick-dots li.slick-active button:before{
  color:#018CF1;
}
.slick-slider .slick-dots li button:before{
  font-size: 8px;
}
